import { object, string } from 'yup'
import { useFormik } from 'formik'
import { FC } from 'react'

import useEmailSubsribeContactList from 'hooks/useEmailSubsribeContactList'

import { Button, Input, Typography, GlowHeading, Result } from '~ui'
import { EMAIL } from 'constants/schema'
import { Modal } from '~shared'

import styles from './MainSection.module.scss'

const { Text } = Typography

interface SubscribeModalProps {
  onClose: () => void
  visible: boolean
  sendGridListName?: string
  sendGridListId?: string
  title: string
  text?: string
  subscribeButtonText?: string
  cancelButtonText?: string
}

const SubscribeModal: FC<SubscribeModalProps> = ({
  onClose,
  visible,
  sendGridListName,
  sendGridListId,
  title,
  text,
  subscribeButtonText,
  cancelButtonText,
}) => {
  const [subscribe, { data, loading }] = useEmailSubsribeContactList()

  const { errors, handleSubmit, values, handleBlur, touched, handleChange } = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    onSubmit: ({ email, name }) =>
      subscribe({
        variables: {
          data: { email, firstName: name },
          listNames: [sendGridListName],
          listIds: [sendGridListId],
        },
      }),
    validationSchema: object({
      email: EMAIL(),
      name: string().required('Please enter your name.'),
    }).required(),
  })

  return (
    <Modal isOpen={visible} onClose={onClose}>
      {data?.addOrUpdateContactList.__typename === 'ContactMutationSuccess' ? (
        <Result
          title="Success!"
          subtitle="Thanks for subscribing."
          actions={[
            <Button key="close" variant="primary" onClick={onClose}>
              Close
            </Button>,
          ]}
        />
      ) : (
        <>
          <GlowHeading Tag="h4" className={styles.title}>
            {title}
          </GlowHeading>
          {text && <Text className={styles.text}>{text}</Text>}
          <form onSubmit={handleSubmit} className={styles.form}>
            <Input
              large
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Your Name"
              error={errors.name && touched.name && errors.name}
            />
            <Input
              large
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Your Email"
              error={errors.email && touched.email && errors.email}
            />
            <div className={styles.bottom}>
              <Button variant="primary" clr="red" uppercase onClick={onClose}>
                {cancelButtonText || 'Cancel'}
              </Button>
              <Button
                variant="primary"
                type="submit"
                uppercase
                loading={loading}
                disabled={loading}
              >
                {subscribeButtonText || 'Subscribe'}
              </Button>
            </div>
          </form>
        </>
      )}
    </Modal>
  )
}

export default SubscribeModal
