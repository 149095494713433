import { FC, ReactNode } from 'react'
import { GlowHeading, Typography } from 'components/ui'

import styles from './Result.module.scss'

const Result: FC<{ title?: string; subtitle?: string; actions?: ReactNode }> = ({
  title,
  subtitle,
  actions,
}) => (
  <div className={styles.wrapper}>
    <GlowHeading Tag="h2" color="g-green-darker" className={styles.title}>
      {title}
    </GlowHeading>
    {subtitle && <Typography.Text>{subtitle}</Typography.Text>}
    {actions && <div className={styles.actions}>{actions}</div>}
  </div>
)

export default Result
