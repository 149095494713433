import classnames from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import useGallery, { UseGalleryParams } from 'hooks/useGallery'

import styles from './SlideshowGallery.module.scss'

export type SlideshowGalleryProps = {
  color?: GThemeColorsNew
} & UseGalleryParams

const SlideshowGallery: FC<SlideshowGalleryProps> = ({
  images,
  interval,
  swipeCoefficient = 0.5,
  color,
}) => {
  const { index, setIndex, smooth, galleryRef, slidesRef } = useGallery({
    images,
    interval,
    swipeCoefficient,
  })

  const { push } = useRouter()

  // ? use array of refs
  const vimeoPlayers = useRef({})

  useEffect(() => {
    images.forEach((slide) => {
      if (slide.type === 'video' && slide.videoType === 'vimeo' && slide !== images[index]) {
        const player = vimeoPlayers.current[slide.vimeo.videoId]
        player?.pause()
      }

      if (slide.type === 'video' && slide.videoType === 'vimeo' && slide === images[index]) {
        const player = vimeoPlayers.current[slide.vimeo.videoId]
        player?.play()
      }
    })
  }, [index, images])

  return (
    <div className={classnames(styles.wrapper, { [styles[color]]: color })}>
      {images.some(({ type, videoType }) => type === 'video' && videoType === 'vimeo') && (
        <script src="https://player.vimeo.com/api/player.js" />
      )}

      <div className={styles.gallery} ref={galleryRef}>
        <div
          ref={slidesRef}
          className={classnames(styles.slides, { [styles['slides-smooth']]: smooth })}
        >
          {images.map(
            (
              {
                url,
                description,
                objectFit,
                objectPosition,
                link,
                type = 'image',
                videoType = 'vimeo',
                key,
                vimeo,
              },
              i
            ) => {
              if (type === 'image')
                return (
                  <div
                    aria-hidden
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${key || url}-image-${i}`}
                    className={classnames(styles.slide, { [styles['slide-clickable']]: link })}
                    onClick={() => link && push(link)}
                  >
                    <img
                      src={url}
                      alt={description || `slide-${i + 1}`}
                      style={{ objectFit, objectPosition }}
                    />
                  </div>
                )

              if (type === 'video' && videoType === 'vimeo')
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${key || url}-video-${i}`} className={styles.slide}>
                    <iframe
                      src={`https://player.vimeo.com/video/${vimeo.videoId}?autoplay=1&loop=1&color=73ff73&title=0&byline=0&portrait=0&muted=1`}
                      allow="autoplay; fullscreen; picture-in-picture"
                      ref={(ref) => {
                        if (ref && !vimeoPlayers.current[vimeo.videoId] && window.Vimeo?.Player) {
                          vimeoPlayers.current[vimeo.videoId] = new window.Vimeo.Player(ref)
                        }
                      }}
                      title={vimeo.title}
                      frameBorder="0"
                      id="video"
                    />
                  </div>
                )

              return null
            }
          )}
        </div>
      </div>

      <div className={styles.dots}>
        {images.map((item, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.url || item.key}-dot-${i}`}
            aria-hidden
            className={classnames(styles.dot, { [styles['dot-active']]: i === index })}
            onClick={() => setIndex(i)}
          />
        ))}
      </div>
    </div>
  )
}

export default SlideshowGallery
