import { useState, FC } from 'react'
import { useIsomorphicLayoutEffect } from '~hooks'
import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import classNames from 'classnames'

import styles from './markdown.module.scss'

type MarkdownProps = {
  md: string
  variant?: 'standart'
}

const Markdown: FC<MarkdownProps> = ({ md, variant }) => {
  const [html, setHtml] = useState('')

  const mdToHtml = async (markdown: string) => {
    const result = await unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .process(markdown)
    setHtml(result.toString())
  }

  useIsomorphicLayoutEffect(() => {
    mdToHtml(md)
  }, [md])

  return (
    <div
      className={classNames(styles.description, { [styles[variant]]: variant })}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Markdown
