import classnames from 'classnames'
import { FC } from 'react'

import GlowHeading, { GlowHeadingProps } from 'components/ui/GlowHeading'
import { Markdown, SlideshowGalleryV2 } from '~shared'
import { Image } from 'hooks/useGallery'
import Button, { ButtonProps } from '~ui/Button'
import { Icon, Wrapper } from 'components/ui'
import { useScreenClass } from '~hooks'
import { IconTypes } from '~ui/Icon'

import styles from './FeaturedEvent.module.scss'

type Tag = {
  label: string
  color?: GThemeColorsNew
}

type FeaturedEventProps = {
  description: string
  images: Image[]
  title?: string
  reverse?: boolean
  actions?: Array<ButtonProps & { label?: string }> | (ButtonProps & { label?: string })
  color?: GThemeColorsNew
  galleryInterval?: number
  headingProps?: Partial<GlowHeadingProps>
  subtitle?: { text: string; placing?: 'above' | 'under' }
  tags?: Tag[]
  withSign?: boolean
  icon?: IconTypes
}

const FeaturedEvent: FC<FeaturedEventProps> = ({
  title,
  description,
  reverse,
  actions = [],
  images = [],
  galleryInterval,
  color = 'light-purple',
  headingProps,
  subtitle,
  tags = [],
  withSign = true,
  icon,
}) => {
  const { isDesktop } = useScreenClass()

  return (
    <Wrapper>
      <div
        className={classnames(styles.content, {
          [styles.reverse]: reverse,
          [styles[color]]: color,
        })}
      >
        {withSign && (
          <div className={styles.sign}>
            <div />
            <div />
          </div>
        )}

        <div className={styles.left}>
          <div className={styles.header}>
            {icon && isDesktop && (
              <Icon
                name="granted-credit-thin"
                className={styles.icon}
                glowColor={color}
                height={64}
                width={64}
                glow
              />
            )}

            {tags && !!tags.length && (
              <div className={styles.tags}>
                {tags.map(({ label, color: c }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={classnames(styles.tag, styles[c || color])} key={`tag-${index}`}>
                    {label}
                  </div>
                ))}
              </div>
            )}

            {subtitle && subtitle.placing === 'above' && (
              <span className={styles.subtitle}>{subtitle.text}</span>
            )}

            <GlowHeading
              Tag="h2"
              color={color}
              uppercase
              className={classnames(styles.title, {
                [styles['turn-off-animation']]:
                  typeof headingProps?.glow !== 'undefined' && !headingProps?.glow,
              })}
              {...headingProps}
            >
              {title}
            </GlowHeading>

            {subtitle && subtitle.placing === 'under' && (
              <span className={styles.subtitle}>{subtitle.text}</span>
            )}
          </div>
          <div className={styles.description}>
            <Markdown variant="standart" md={description} />
          </div>

          <div className={styles['buttons-wrapper']}>
            {(Array.isArray(actions) ? actions : [actions]).map(
              ({ label, ...buttonProps }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Button key={`action-${index}`} clr={color} {...buttonProps}>
                  {label}
                </Button>
              )
            )}
          </div>
        </div>

        <div className={styles.right}>
          {images.length === 1 ? (
            <img
              style={{ objectFit: images[0].objectFit, objectPosition: images[0].objectPosition }}
              className={styles.image}
              src={images[0].url}
              alt={title}
            />
          ) : (
            <SlideshowGalleryV2
              interval={galleryInterval}
              swipeCoefficient={0.3}
              images={images}
              color={color}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default FeaturedEvent
